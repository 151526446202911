import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const HomeService = createApi({
    reducerPath: "HomeService",
    baseQuery: fetchBaseQuery({
        baseUrl:
            "https://studioprobackend.developer-ourbase-camp.com/api/v1/",
    }),
    endpoints: (builder) => {
        return {
            homeApi: builder.query({
                query: () => {
                    return {
                        url: "homepage",
                        method: "GET",
                    };
                },
            }),
            newLetterApi: builder.mutation({
                query: (email) => {
                    return {
                        url: "newsletter_subscribe",
                        method: "POST",
                        body: email
                    };
                },
            }),
        };
    },
});
export const { useHomeApiQuery, useNewLetterApiMutation } = HomeService;
export default HomeService;
