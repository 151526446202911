import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const ContactService = createApi({
    reducerPath: "ContactService",
    baseQuery: fetchBaseQuery({
        baseUrl:
            "https://studioprobackend.developer-ourbase-camp.com/api/v1/",
    }),
    endpoints: (builder) => {
        return {
            contactUsApi: builder.mutation({
                query: (body) => {
                    return {
                        url: "contact_query",
                        method: "POST",
                        body,
                    };
                },
            }),
        };
    },
});
export const { useContactUsApiMutation } = ContactService;
export default ContactService;
