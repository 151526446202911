import { configureStore } from "@reduxjs/toolkit";
import AuthReducer from "./reducers/authReducers";
import cartReducers from "./reducers/cartReducers";
import globalReducers from "./reducers/globalReducers";
import AuthService from "./services/AuthService";
import FaqsServices from "./services/FaqsServices";
import AboutService from "./services/Pages/AboutService";
import ClassesService from "./services/Pages/ClassesService";
import ContactService from "./services/Pages/ContactService";
import HomeService from "./services/Pages/HomeService";
import ProfileService from "./services/Pages/ProfileService";
import ShopService from "./services/Pages/ShopService";


const Store = configureStore({
    reducer: {
        [HomeService.reducerPath]: HomeService.reducer,
        [AboutService.reducerPath]: AboutService.reducer,
        [AuthService.reducerPath]: AuthService.reducer,
        [ContactService.reducerPath]: ContactService.reducer,
        [ProfileService.reducerPath]: ProfileService.reducer,
        [FaqsServices.reducerPath]: FaqsServices.reducer,
        [ClassesService.reducerPath]: ClassesService.reducer,
        [ShopService.reducerPath]: ShopService.reducer,
        globalReducer: globalReducers,
        AuthReducer: AuthReducer,
        cartReducers: cartReducers,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat([
            HomeService.middleware,
            AboutService.middleware,
            AuthService.middleware,
            ContactService.middleware,
            ProfileService.middleware,
            FaqsServices.middleware,
            ShopService.middleware,
            ClassesService.middleware,
        ]),
});
export default Store;
