import PublicRoutes from "./routes/PublicRoutes";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import "../src/assets/css/style.css";
import { useEffect } from "react";
import { Toaster } from "react-hot-toast";


function App() {

	return (
		<>
			<Toaster
				position="top-right"
				reverseOrder={false}
			/>
			<PublicRoutes />
		</>
	);
}

export default App;
