import { createSlice } from '@reduxjs/toolkit';
const cartData = localStorage.getItem('cart');
const cartArray = cartData ? JSON.parse(cartData) : [];
function allItems(data) {
    let items = 0;
    for (let i = 0; i < data.length; i++) {
        items += data[i].quantity;
    }
    return items;
}
function calcuateTotal(data) {
    let total = 0;
    for (let i = 0; i < data.length; i++) {
        total += data[i].discount ? data[i].discountedprice * data[i].quantity : data[i].unit_price * data[i].quantity;
    }
    return total;
}
const cartReducers = createSlice({
    name: 'cart',
    initialState: {
        cart: cartArray.length > 0 ? cartArray : [],
        items: cartArray.length > 0 ? allItems(cartArray) : 0,
        total: cartArray.length > 0 ? calcuateTotal(cartArray) : 0,
    },
    reducers: {
        addCart: (state, { payload }) => {
            state.cart.push(payload);
            state.items += 1;
            state.total += payload.discount ? payload.discount : payload.unit_price * 1;
        },
        addQuantity: (state, { payload }) => {
            const find = state.cart.find((item) => item.id === payload);
            if (find) {
                if (!find.kit_charges) {
                    find.quantity += 1;
                    state.items += 1;
                    state.total += find.discount ? find.discount : find.unit_price;
                    const index = state.cart.indexOf(find);
                    state.cart[index] = find;
                    localStorage.setItem('cart', JSON.stringify(state.cart));
                } else {
                    return
                }
            }
        },
        incQuantity: (state, { payload }) => {
            const find = state.cart.find((item) => item.id === payload);
            if (find) {
                if (!find.kit_charges) {
                    find.quantity += 1;
                    state.items += 1;
                    state.total += find.discount ? find.discount : find.unit_price;
                    const index = state.cart.indexOf(find);
                    state.cart[index] = find;
                    localStorage.setItem('cart', JSON.stringify(state.cart));
                } else {
                    return
                }
            }
        },
        decQuantity: (state, { payload }) => {
            const find = state.cart.find((item) => item.id === payload);
            if (find && find.quantity > 1) {
                if (!find.kit_charges) {
                    find.quantity -= 1;
                    state.items -= 1;
                    state.total -= find.discount ? find.discount : find.unit_price;
                    const index = state.cart.indexOf(find);
                    state.cart[index] = find;
                    localStorage.setItem('cart', JSON.stringify(state.cart));
                } else {
                    return
                }
            }
        },
        removeItem: (state, { payload }) => {
            const find = state.cart.find((item) => item.id === payload);
            if (find) {
                const index = state.cart.indexOf(find);
                state.items -= find.quantity;
                state.total -= find.discount ? find.discount : find.unit_price * find.quantity;
                state.cart.splice(index, 1);
                localStorage.setItem('cart', JSON.stringify(state.cart));
            }
        },
        emptyCart: (state) => {
            state.cart = [];
            state.items = 0;
            state.total = 0;
            localStorage.removeItem('cart');
        },
    },
});
export const {
    addCart,
    incQuantity,
    decQuantity,
    removeItem,
    emptyCart,
    addQuantity,
} = cartReducers.actions;
export default cartReducers.reducer;
