import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const ShopService = createApi({
    reducerPath: "ShopService",
    tagTypes: "details",
    baseQuery: fetchBaseQuery({
        baseUrl:
            "https://studioprobackend.developer-ourbase-camp.com/api/v1/",
        prepareHeaders: (headers, { getState }) => {
            const reducers = getState();
            const token = reducers?.AuthReducer?.userToken;
            headers.set("authorization", token ? `Bearer ${token}` : "");
            return headers;
        },
    }),
    endpoints: (builder) => {
        return {
            getAllProductApi: builder.query({
                query: (datas) => {
                    return {
                        url: `products/all?slug=${datas.search ? datas.search : ""}&category_id=${datas.activeClass}&limit=${datas.limit}&offset=${datas.page}`,
                        method: "GET",
                    };
                },
            }),
            getProductCateApi: builder.query({
                query: () => {
                    return {
                        url: "categories/all",
                        method: "GET",
                    };
                },
            }),
            getProductDetailApi: builder.query({
                query: (id) => {
                    return {
                        url: `products/details/${id}`,
                        method: "GET",
                    };
                },
                providesTags: ["details"],
            }),
            getProductReviewSubmitApi: builder.mutation({
                query: (body) => {
                    return {
                        url: `products/reviews/submit`,
                        method: "POST",
                        body
                    };
                },
                invalidatesTags: ["details"],
            }),
            myPuchaseCourseQuizApi: builder.mutation({
                query: (datas) => {
                    return {
                        url: `quiz/store`,
                        method: "POST",
                        body: datas
                    };
                },
                invalidatesTags: ["details"],
            }),
            getMyPuchaseCourseApi: builder.query({
                query: (datas) => {

                    return {
                        url: `courses/purchasedcourses?category_id=${datas?.statetab}&course_category_id=${datas?.category ? datas?.category : ""}`,
                        method: "GET",
                    };
                },
            }),
            getAssigmentDataApi: builder.query({
                query: (id) => {
                    return {
                        url: `assignment?course_charge_id=${id}`,
                        method: "GET",
                    };
                },
                providesTags: ["details"],
            }),
            myPuchaseCourseAssignmentApi: builder.mutation({
                query: (datas) => {
                    return {
                        url: `assignment/store`,
                        method: "POST",
                        body: datas
                    };
                },
            }),

            shopCardCouponApi: builder.mutation({
                query: (datas) => {
                    return {
                        url: `coupon/apply`,
                        method: "POST",
                        body: datas
                    };
                },
            }),

        };
    },
});
export const { useGetAllProductApiQuery, useGetProductCateApiQuery, useGetProductDetailApiQuery, useGetProductReviewSubmitApiMutation, useGetMyPuchaseCourseApiQuery, useMyPuchaseCourseAssignmentApiMutation, useMyPuchaseCourseQuizApiMutation, useShopCardCouponApiMutation, useGetAssigmentDataApiQuery } = ShopService;
export default ShopService;
