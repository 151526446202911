import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const AboutService = createApi({
    reducerPath: "AboutService",
    baseQuery: fetchBaseQuery({
        baseUrl:
            "https://studioprobackend.developer-ourbase-camp.com/api/v1/",
    }),
    endpoints: (builder) => {
        return {
            aboutApi: builder.query({
                query: () => {
                    return {
                        url: "aboutus",
                        method: "GET",
                    };
                },
            }),
        };
    },
});
export const { useAboutApiQuery } = AboutService;
export default AboutService;
