import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const ClassesService = createApi({
    reducerPath: "ClassesService",
    baseQuery: fetchBaseQuery({
        baseUrl:
            "https://studioprobackend.developer-ourbase-camp.com/api/v1/",
        prepareHeaders: (headers, { getState }) => {
            const reducers = getState();
            const token = reducers?.AuthReducer?.userToken;
            headers.set("authorization", token ? `Bearer ${token}` : "");
            return headers;
        },
    }),
    endpoints: (builder) => {
        return {
            classessCateApi: builder.query({
                query: () => {
                    return {
                        url: "coursecategory",
                        method: "GET",
                    };
                },
            }),
            classessApi: builder.query({
                query: () => {
                    return {
                        url: "courseclass",
                        method: "GET",
                    };
                },
            }),
            classessDetailApi: builder.query({
                query: (id) => {
                    return {
                        url: `courses?id=${id}`,
                        method: "GET",
                    };
                },
            }),
            courseChangeApi: builder.query({
                query: (id) => {
                    return {
                        url: `courses/charge?id=${id}`,
                        method: "GET",
                    };
                },
            }),
            courseAttributeApi: builder.query({
                query: (datas) => {
                    return {
                        url: `coursematerial?course_charge_id=${datas?.course_charge_id}&type=${datas?.type}`,
                        method: "GET",
                    };
                },
            }),
            coursePurchaseCateApi: builder.query({
                query: () => {
                    return {
                        url: `courses/purchasedcourses/categories`,
                        method: "GET",
                    };
                },
            }),
            coursePurchaseDetailsApi: builder.query({
                query: (id) => {
                    return {
                        url: `coursematerial/video?video_id=${id}`,
                        method: "GET",
                    };
                },
            }),
            checkCoursePurchaseApi: builder.mutation({
                query: (body) => {
                    return {
                        url: `cart/check`,
                        method: "POST",
                        body
                    };
                },
            }),
        };
    },
});
export const { useClassessCateApiQuery, useClassessApiQuery, useClassessDetailApiQuery, useCourseChangeApiQuery, useCourseAttributeApiQuery, useCoursePurchaseCateApiQuery, useCoursePurchaseDetailsApiQuery, useCheckCoursePurchaseApiMutation } = ClassesService;
export default ClassesService;
