import React, { useEffect, lazy, Suspense } from "react";
import $ from "jquery";
import { BrowserRouter, Route, Routes } from "react-router-dom";

const Home = lazy(() => import('../Pages/Home/Home'))
const About = lazy(() => import('../Pages/About/About'))
const EnterEmail = lazy(() => import('../Pages/auth/EnterEmail'))
const Login = lazy(() => import('../Pages/auth/Login'))
const Verification = lazy(() => import('../Pages/auth/Verification'))
const NewPassword = lazy(() => import('../Pages/auth/NewPassword'))
const SignUp = lazy(() => import('../Pages/auth/SignUp'))
const BilingDetails = lazy(() => import('../Pages/checkout/BilingDetails'))
const Checkout = lazy(() => import('../Pages/checkout/checkout'))
const Classes = lazy(() => import('../Pages/Classes/Classes'))
const Faq = lazy(() => import('../Pages/Faq/Faq'))
const NewsDetail = lazy(() => import('../Pages/News/NewsDetail'))
const Privacy = lazy(() => import('../Pages/Privacy/Privacy'))
const Terms = lazy(() => import('../Pages/Privacy/Terms'))
const ProductCart = lazy(() => import('../Pages/ProductCart/ProductCart'))
const BladingDetail = lazy(() => import('../Pages/Profile/BladingDetail'))
const MyCourses = lazy(() => import('../Pages/Profile/MyCourses'))
const Myorder = lazy(() => import('../Pages/Profile/Myorder'))
const MyProfile = lazy(() => import('../Pages/Profile/MyProfile'))
const OnlineCourses = lazy(() => import('../Pages/Profile/OnlineCourses'))
const Profile = lazy(() => import('../Pages/Profile/Profile'))
const Thankyou = lazy(() => import('../Pages/Thankyou/Thankyou'))
const Wishlist = lazy(() => import('../Pages/wishlist/wishlist'))
const Contact = lazy(() => import('../Pages/Contact/Contact'))
const News = lazy(() => import('../Pages/News/News'))
const Shop1 = lazy(() => import('../Pages/Shop/Shop1'))
const Shop2 = lazy(() => import('../Pages/Shop/Shop2'))
const ClassesDetail = lazy(() => import('../Pages/Classes/ClassesDetail'))
const Addresses = lazy(() => import('../Pages/Profile/Addresses'))
const Payment = lazy(() => import('../Pages/Profile/Payment'))
const ChangePassword = lazy(() => import('../Pages/Profile/ChangePassword'))
const Order = lazy(() => import('../Pages/Profile/Order'))
const InPerson = lazy(() => import('../Pages/Profile/InPerson'))
const HybridCourses = lazy(() => import('../Pages/Profile/HybridCourses'))
const Private = lazy(() => import('./Private'))
const Public = lazy(() => import('./Public'))


const PublicRoutes = () => {

	useEffect(() => {
		$("document").ready(function () {
			$("header .canvas-icon i").click(function () {
				$("header .mobile-header").addClass("show");
			});

			$("header .mobile-header .cancel").click(function () {
				$("header .mobile-header").removeClass("show");
			});
		});
	}, []);
	return (
		<>

			<BrowserRouter>
				<Suspense fallback={<div style={{ display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center", color: "#fff", fontSize: "20px", fontFamily: "sans-serif", background: "black", height: "100vh" }}>Loading...</div>}>
					<Routes>
						<Route path="/" element={<Home />} />
						<Route path="/aboutus" element={<About />} />
						<Route path="/classes" element={<Classes />} />
						<Route path="/classes-detail/:id" element={<ClassesDetail />} />
						<Route path="/news" element={<News />} />
						<Route path="/newsdetail/:id" element={<NewsDetail />} />
						<Route path="/privacy" element={<Privacy />} />
						<Route path="/terms" element={<Terms />} />
						<Route path="/faq" element={<Faq />} />
						<Route path="/contact" element={<Contact />} />
						<Route path="/shop" element={<Shop1 />} />
						<Route path="/shopdetail/:id" element={<Shop2 />} />
						<Route path="*" element={<Home />} />
						<Route
							path="/wishlist"
							element={
								<Private>
									<Wishlist />
								</Private>
							}
						/>

						<Route
							path="/productcart"
							element={
								<Private>
									<ProductCart />
								</Private>
							}
						/>

						<Route
							path="/bilingDetail"
							element={
								<Private>
									<BilingDetails />
								</Private>
							}
						/>

						<Route
							path="/checkout"
							element={
								<Private>
									<Checkout />
								</Private>
							}
						/>
						{/* /:order_id/:paymentId/:token/:PayerID */}
						<Route
							path="/Thankyou"
							element={
								<Private>
									<Thankyou />
								</Private>
							}
						/>

						<Route
							path="/profile"
							element={
								<Private>
									<Profile />
								</Private>
							}
						/>

						<Route
							path="/mycourses"
							element={
								<Private>
									<MyCourses />
								</Private>
							}
						/>

						<Route
							path="/myprofile"
							element={
								<Private>
									<MyProfile />
								</Private>
							}
						/>

						<Route
							path="/myaddress"
							element={
								<Private>
									<Addresses />
								</Private>
							}
						/>

						<Route
							path="/myaddress"
							element={
								<Private>
									<Addresses />
								</Private>
							}
						/>

						<Route
							path="/payment"
							element={
								<Private>
									<Payment />
								</Private>
							}
						/>

						{/* <Route
						path="/Payment"
						element={
							<Private>
								<Payment />
							</Private>
						}
					/> */}

						<Route
							path="/myorder"
							element={
								<Private>
									<Myorder />
								</Private>
							}
						/>

						<Route
							path="/order/:id"
							element={
								<Private>
									<Order />
								</Private>
							}
						/>

						<Route
							path="/bladingdetail/:id"
							element={
								<Private>
									<BladingDetail />
								</Private>
							}
						/>

						<Route
							path="/onlinecourses/:id"
							element={
								<Private>
									<OnlineCourses />
								</Private>
							}
						/>
						<Route
							path="/inperson/:id"
							element={
								<Private>
									<InPerson />
								</Private>
							}
						/>
						<Route
							path="/hybridcourses/:id"
							element={
								<Private>
									<HybridCourses />
								</Private>
							}
						/>
						<Route
							path="/login"
							element={
								<Public>
									<Login />
								</Public>
							}
						/>
						<Route
							path="/enteremail"
							element={
								<Public>
									<EnterEmail />
								</Public>
							}
						/>
						<Route
							path="/newpassword/:token"
							element={
								<Public>
									<NewPassword />
								</Public>
							}
						/>
						<Route
							path="/signup"
							element={
								<Public>
									<SignUp />
								</Public>
							}
						/>
						<Route
							path="/verification"
							element={
								<Public>
									<Verification />
								</Public>
							}
						/>
						<Route
							path="/changePassword"
							element={
								<Private>
									<ChangePassword />
								</Private>
							}
						/>

					</Routes>
				</Suspense>
			</BrowserRouter>
		</>
	);
};

export default PublicRoutes;
