import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const ProfileService = createApi({
    reducerPath: "ProfileService",
    tagTypes: "addadress",
    baseQuery: fetchBaseQuery({
        baseUrl:
            "https://studioprobackend.developer-ourbase-camp.com/api/v1/",
        prepareHeaders: (headers, { getState }) => {
            const reducers = getState();
            const token = reducers?.AuthReducer?.userToken;
            headers.set("authorization", token ? `Bearer ${token}` : "");
            return headers;
        },
    }),

    endpoints: (builder) => {
        return {
            getProfileApi: builder.query({
                query: () => {
                    return {
                        url: "customer/info",
                        method: "GET",
                    };
                },
                providesTags: ["addadress"],
            }),
            editProfileApi: builder.mutation({
                query: (body) => {
                    return {
                        url: "customer/update-profile",
                        method: "POST",
                        body,
                    };
                },
                invalidatesTags: ["addadress"],
            }),
            addAddressApi: builder.mutation({
                query: (body) => {
                    return {
                        url: "customer/address/add",
                        method: "POST",
                        body,
                    };
                },
                invalidatesTags: ["addadress"],
            }),
            addMakeDefaultAddressApi: builder.mutation({
                query: (datas) => {
                    return {
                        url: `customer/address/default?address_id=${datas?.id}`,
                        method: "POST",
                        body: datas?.data
                    };
                },
                invalidatesTags: ["addadress"],
            }),
            getMakeDefaultAddressApi: builder.query({
                query: () => {
                    return {
                        url: `customer/address/default`,
                        method: "Get",
                    };
                },
                providesTags: ["addadress"],
            }),
            getAddressApi: builder.query({
                query: () => {
                    return {
                        url: `customer/address/list`,
                        method: "GET",
                    };
                },
                providesTags: ["addadress"],
            }),
            getSingleAddress: builder.query({
                query: (id) => {
                    return {
                        url: `customer/address/list?id=${id ? id : ""}`,
                        method: "GET",
                    };
                },
                providesTags: ["addadress"],
            }),
            getAddressUpdateApi: builder.mutation({
                query: (body) => {
                    return {
                        url: "customer/address/update",
                        method: "POST",
                        body
                    };
                },
                invalidatesTags: ["addadress"],
            }),
            getAddressDeleteApi: builder.mutation({
                query: (id) => {
                    return {
                        url: `customer/address?address_id=${id}`,
                        method: "DELETE",
                    };
                },
                invalidatesTags: ["addadress"],
            }),
            getThankyouApi: builder.query({
                query: (href) => {
                    return {
                        url: `customer/order/status${href}`,
                        method: "GET",
                    };
                },
            }),
        };
    },
});
export const { useGetProfileApiQuery, useEditProfileApiMutation, useAddAddressApiMutation, useGetAddressApiQuery, useGetSingleAddressQuery, useGetAddressUpdateApiMutation, useGetAddressDeleteApiMutation, useAddMakeDefaultAddressApiMutation, useGetMakeDefaultAddressApiQuery, useGetThankyouApiQuery } = ProfileService;
export default ProfileService;
