import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const FaqsServices = createApi({
    reducerPath: "faqs",
    tagTypes: "faqsdta",
    baseQuery: fetchBaseQuery({
        baseUrl:
            "https://studioprobackend.developer-ourbase-camp.com/api/v1/",

    }),
    endpoints: (builder) => {
        return {
            faqs: builder.query({
                query: () => {
                    return {
                        url: "faq",
                        method: "GET",

                    };
                },
            }),
            siteSetting: builder.query({
                query: () => {
                    return {
                        url: "sitesetting",
                        method: "GET",

                    };
                },
            }),
            footerApi: builder.query({
                query: () => {
                    return {
                        url: "footer",
                        method: "GET",

                    };
                },
            }),
            blogCategory: builder.query({
                query: () => {
                    return {
                        url: "blogscategory",
                        method: "GET",

                    };
                },
            }),
            blogCategoryById: builder.query({
                query: (id) => {
                    return {
                        url: `/blogs/show/${id}`,
                        method: "GET",
                    };
                },
            })
        };
    },
});
export const {
    useFaqsQuery,
    useSiteSettingQuery,
    useBlogCategoryQuery,
    useFooterApiQuery,
    useBlogCategoryByIdQuery
} = FaqsServices;
export default FaqsServices;
