import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const AuthService = createApi({
    reducerPath: "auth",
    tagTypes: "wishlist",
    baseQuery: fetchBaseQuery({
        baseUrl:
            "https://studioprobackend.developer-ourbase-camp.com/api/v1/",
        prepareHeaders: (headers, { getState }) => {
            const reducers = getState();
            const token = reducers?.AuthReducer?.userToken;
            headers.set("authorization", token ? `Bearer ${token}` : "");
            return headers;
        },
    }),
    endpoints: (builder) => {
        return {
            authLogin: builder.mutation({
                query: (loginData) => {
                    return {
                        url: "auth/login",
                        method: "POST",
                        body: loginData,
                    };
                },
            }),
            userRegister: builder.mutation({
                query: (data) => {
                    return {
                        url: "auth/register",
                        method: "POST",
                        body: data,
                    };
                },
            }),
            userForgetPass: builder.mutation({
                query: (data) => {
                    return {
                        url: "auth/forgot-password",
                        method: "POST",
                        body: data,
                    };
                },
            }),
            userResestPass: builder.mutation({
                query: (data) => {
                    return {
                        url: "auth/reset-password",
                        method: "POST",
                        body: data,
                    };
                },
            }),
            userOTPVerfied: builder.mutation({
                query: (data) => {
                    return {
                        url: "auth/verify-otp",
                        method: "POST",
                        body: data,
                    };
                },
            }),
            userChangePass: builder.mutation({
                query: (data) => {
                    return {
                        url: "auth/change-password",
                        method: "POST",
                        body: data,
                    };
                },
            }),
            userAddToWishlist: builder.mutation({
                query: (body) => {
                    return {
                        url: "customer/wish-list/add",
                        method: "POST",
                        body
                    }
                },
                invalidatesTags: ["wishlist"],
            }),
            userRemoveToWishlist: builder.mutation({
                query: (id) => {
                    return {
                        url: `customer/wish-list/remove?product_id=${id}`,
                        method: "DELETE",
                    }
                },
                invalidatesTags: ["wishlist"],
            }),
            userGetToWishlist: builder.query({
                query: () => {
                    return {
                        url: "customer/wish-list",
                        method: "GET",
                    }
                },
                providesTags: ["wishlist"],
            }),
            userGetOrder: builder.query({
                query: () => {
                    return {
                        url: "customer/order/list",
                        method: "GET",
                    }
                },
            }),
            userGetOrderDetails: builder.query({
                query: (id) => {
                    return {
                        url: `customer/order/details?order_id=${id}`,
                        method: "GET",
                    }
                },
            }),
            userPlaceOrderApi: builder.mutation({
                query: (body) => {
                    return {
                        url: "customer/order/place",
                        method: "POST",
                        body
                    }
                },
            }),
        };
    },
});
export const {
    useAuthLoginMutation,
    useUserPlaceOrderApiMutation,
    useUserRegisterMutation,
    useUserForgetPassMutation,
    useUserChangePassMutation,
    useUserResestPassMutation,
    useUserOTPVerfiedMutation,
    useUserAddToWishlistMutation,
    useUserRemoveToWishlistMutation,
    useUserGetToWishlistQuery,
    useUserGetOrderQuery,
    useUserGetOrderDetailsQuery,

} = AuthService;
export default AuthService;
